import React, { useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import axios from 'axios';

const PaymentForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    purpose: '',
    amount: 0,
  });
  const [loading, setLoading] = useState(false);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Create order on the server
      const orderResponse = await axiosInstance.post("/checkout", formData);
      const { id: order_id, amount, currency } = orderResponse.data.order; // Accessing correctly
      console.log(orderResponse.data); // Log order response to ensure correct data structure

      // Initialize Razorpay
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Razorpay Key ID
        amount: amount,
        currency: currency,
        name: "Advisions Research",
        description: "Payment for " + formData.purpose,
        order_id: order_id,
        prefill: {
          name: formData.name,
          email: formData.email,
        },
        notes: {
          purpose: formData.purpose,
        },
        theme: {
          color: "#3399cc",
        },
        handler: function (response) {
          // Payment verification call
          axiosInstance
            .post("/paymentverification", {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              purpose: formData.purpose, // Correctly reference formData
              amount: formData.amount,
              name: formData.name,
              email: formData.email,
            })
            .then((res) => {
              console.log(res.data);
              const invoiceLink = res.data.invoiceLink; // Get invoice link
              window.location.href = `https://www.advisionslab.com/paymentsuccess?reference=${response.razorpay_payment_id}&invoice=${encodeURIComponent(invoiceLink)}`;
            })
            .catch((err) => {
              console.error("Payment verification error:", err);
            });
        },
      };

      if (window.Razorpay) {
        const razor = new window.Razorpay(options);
        razor.open();
      } else {
        console.error("Razorpay SDK not loaded");
      }
    } catch (error) {
      console.error('Payment Error:', error);
      alert('Payment initiation failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="formbold-main-wrapper">
        <div className="formbold-form-wrapper">
          <form onSubmit={handlePayment}>
            <div className="flex flex-wrap formbold--mx-3">
              <div className="w-full sm:w-half formbold-px-3">
                <div className="formbold-mb-5">
                  <label htmlFor="name" className="formbold-form-label">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                    className="formbold-form-input"
                    required
                  />
                </div>
              </div>
              <div className="w-full sm:w-half formbold-px-3">
                <div className="formbold-mb-5">
                  <label htmlFor="email" className="formbold-form-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    className="formbold-form-input"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="formbold-mb-5">
              <label htmlFor="purpose" className="formbold-form-label">Purpose of the purchase</label>
              <input
                type="text"
                name="purpose"
                value={formData.purpose}
                onChange={handleChange}
                placeholder="Enter the purpose of the purchase"
                className="formbold-form-input"
                required
              />
            </div>

            <div className="formbold-mb-5">
              <label htmlFor="amount" className="formbold-form-label">Amount (INR)</label>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                placeholder="0"
                className="formbold-form-input"
                required
                min="1"
              />
            </div>

            <div>
              <button className="formbold-btn" type="submit" disabled={loading}>
                {loading ? 'Processing...' : 'Pay Now'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentForm;
